<template>
    <div
        v-if="entry"
        class="template template--info-page"
    >
        <InfoPageHero :hero="hero" />

        <slot name="default">
            <SubNavigation v-if="entry.subNavigation && entry.subNavigation.length > 0" :links="entry.subNavigation" />
            <FlexibleSections :sections="props.entry.flexibleSections" />
        </slot>
    </div>
</template>

<script setup>
const props = defineProps({
    entry: {
        type: Object,
        default: null,
        required: true
    }
});

const hero = useHeroData(props.entry);
</script>
