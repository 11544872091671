<template>
    <slot name="before" />

    <template
        v-for="(section, index) in filteredSections"
        :key="`section_${index}`"
    >
        <component
            :is="map[section.__typename]"
            v-bind="section"
        />
    </template>

    <slot name="after" />
</template>

<script>
export default {
    props: {
        sections: {
            type: Array,
            required: true,
            default: () => { return []; }
        }
    },

    data() {
        return {
            map: {
                flexibleSections_richText_BlockType: 'FlexibleSectionRichText',
                flexibleSections_images_BlockType: 'FlexibleSectionImages',
                flexibleSections_image_BlockType: 'FlexibleSectionImage',
                flexibleSections_textImage_BlockType: 'FlexibleSectionTextImage',
                flexibleSections_video_BlockType: 'FlexibleSectionVideoEmbed',
                flexibleSections_textVideo_BlockType: 'FlexibleSectionTextVideo',
                flexibleSections_cardSlider_BlockType: 'FlexibleSectionCardSlider',
                flexibleSections_locationSlider_BlockType: 'FlexibleSectionLocationSlider',
                flexibleSections_accordion_BlockType: 'FlexibleSectionAccordion',
                flexibleSections_infoList_BlockType: 'FlexibleSectionInfoList',
                flexibleSections_contactPerson_BlockType: 'FlexibleSectionContactPerson',
                flexibleSections_highlightLinks_BlockType: 'FlexibleSectionHighlightLinks',
                flexibleSections_routeList_BlockType: 'FlexibleSectionRouteList',
                flexibleSections_teamMembers_BlockType: 'FlexibleSectionTeamMembers',
                flexibleSections_partners_BlockType: 'FlexibleSectionPartners',
                flexibleSections_paymentInfo_BlockType: 'FlexibleSectionPaymentInfo',
                flexibleSections_podcastEmbed_BlockType: 'FlexibleSectionPodcastEmbed',
                flexibleSections_newsletter_BlockType: 'FlexibleSectionNewsletterSignup',
                flexibleSections_openingHours_BlockType: 'FlexibleSectionOpeningHours',
                flexibleSections_wordList_BlockType: 'FlexibleSectionWordList',
                flexibleSections_download_BlockType: 'FlexibleSectionDownload',
                flexibleSections_map_BlockType: 'FlexibleSectionMap',
                flexibleSections_form_BlockType: 'FlexibleSectionForm'
            }
        };
    },

    computed: {
        filteredSections() {
            return this.sections.filter(section => this.shouldRender(section));
        }
    },

    methods: {
        shouldRender(section) {
            if (!section) {
                return false;
            }

            if (!section.__typename || !this.map[section.__typename]) {
                return false;
            }

            return true;
        }
    }
};
</script>
