<template>
    <slot name="FormieButton">
        <DnButton v-bind="$attrs" class="base-button">
            <slot />
        </DnButton>
    </slot>
</template>

<script>
import DnButton from '@digitalnatives/button';

export default {
    components: {
        DnButton,
    },
};
</script>
