
// @ts-nocheck


export const localeCodes =  [
  "en",
  "nl"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en-US.js", load: () => import("../lang/en-US.js" /* webpackChunkName: "locale__builds_joods_cultureel_kwartier_jck_app_lang_en_US_js" */), cache: true }],
  "nl": [{ key: "../lang/nl-NL.js", load: () => import("../lang/nl-NL.js" /* webpackChunkName: "locale__builds_joods_cultureel_kwartier_jck_app_lang_nl_NL_js" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../nuxt-i18n.config.js?hash=1f0d8909&config=1" /* webpackChunkName: "__nuxt_i18n_config_js_1f0d8909" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": false,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./nuxt-i18n.config.js",
  "locales": [
    {
      "code": "en",
      "iso": "en-US",
      "files": [
        "lang/en-US.js"
      ]
    },
    {
      "code": "nl",
      "iso": "nl-NL",
      "files": [
        "lang/nl-NL.js"
      ]
    }
  ],
  "defaultLocale": "nl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://jck.nl",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "[slug]": {
      "en": "/[slug]",
      "nl": "/[slug]"
    },
    "stories-and-analysis/index": {
      "en": "/stories-and-analysis",
      "nl": "/verhalen-en-verdieping"
    },
    "stories-and-analysis/[slug]": {
      "en": "/stories-and-analysis/[slug]",
      "nl": "/verhalen-en-verdieping/[slug]"
    },
    "location/[slug]": {
      "en": "/location/[slug]",
      "nl": "/locatie/[slug]"
    },
    "location/[location]/[slug]": {
      "en": "/location/[location]/[slug]",
      "nl": "/locatie/[location]/[slug]"
    },
    "routes/index": {
      "en": "/routes",
      "nl": "/routes"
    },
    "routes/[slug]": {
      "en": "/route/[slug]",
      "nl": "/route/[slug]"
    },
    "space/[slug]": {
      "en": "/space/[slug]",
      "nl": "/ruimte/[slug]"
    },
    "vacancy/[slug]": {
      "en": "/vacancy/[slug]",
      "nl": "/vacature/[slug]"
    },
    "agenda": {
      "en": "/agenda",
      "nl": "/agenda"
    },
    "for-families-and-children": {
      "en": "/for-families-and-children",
      "nl": "/voor-families-en-kinderen"
    },
    "for-schools": {
      "en": "/for-schools",
      "nl": "/voor-scholen"
    },
    "travel-trade": {
      "en": "/travel-trade",
      "nl": "/traveltrade"
    },
    "plan-your-visit": {
      "en": "/plan-your-visit",
      "nl": "/plan-je-bezoek"
    },
    "whats-on": {
      "en": "/whats-on",
      "nl": "/nu-te-zien-en-doen"
    },
    "search": {
      "en": "/search",
      "nl": "/zoeken"
    },
    "theme/[slug]": {
      "en": "/theme/[slug]",
      "nl": "/thema/[slug]"
    },
    "education/[slug]": {
      "en": "/education/[slug]",
      "nl": "/educatie/[slug]"
    },
    "press/index": {
      "en": "/press",
      "nl": "/pers"
    },
    "press/imagery": {
      "en": "/press/imagery",
      "nl": "/pers/beeldmateriaal"
    },
    "press/news": {
      "en": "/press/news",
      "nl": "/pers/nieuws"
    },
    "press/releases": {
      "en": "/press/press-releases",
      "nl": "/pers/persberichten"
    },
    "press/[type]/[slug]": {
      "en": "/press/[type]/[slug]",
      "nl": "/pers/[type]/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en-US",
    "files": [
      {
        "path": "lang/en-US.js"
      }
    ]
  },
  {
    "code": "nl",
    "iso": "nl-NL",
    "files": [
      {
        "path": "lang/nl-NL.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
