<template>
    <DnCookiebar
        :has-preferences="false"
        :title="'Cookies'"
    >
        <template #content>
            <i18n-t keypath="cookiebar.term" tag="span">
                <NuxtLink :to="t('cookiebar.privacy_policy_link')">
                    {{ t('cookiebar.privacy_policy') }}
                </NuxtLink>
            </i18n-t>
        </template>

        <template #decline="{ declineCookies }">
            <BaseButton
                class="cookie-bar__decline button--sm button--auto-inverse"
                @click="declineCookies"
            >
                {{ t('cookiebar.decline') }}
            </BaseButton>
        </template>

        <template #accept="{ acceptAllCookies }">
            <BaseButton
                class="cookie-bar__accept button--sm button--auto-inverse"
                @click="acceptAllCookies"
            >
                {{ t('cookiebar.accept') }}
            </BaseButton>
        </template>
    </DnCookiebar>
</template>

<script setup>
import DnCookiebar from '@digitalnatives/cookie-bar';
const { t } = useI18n({ useScope: 'local' });

</script>

<i18n>
{
    "nl": {
        "cookiebar": {
            "term": "We gebruiken cookies om onze website aan je persoonlijke voorkeuren aan te kunnen passen en onze site te verbeteren. Meer weten? Bekijk ons {0}",
            "privacy_policy": "privacybeleid",
            "privacy_policy_link": "/privacy",
            "change_preferences": "Wijzigen?",
            "saving": "Opslaan…",
            "accept": "Ik ga akkoord",
            "decline": "Nee bedankt"
        }
    },
    "en": {
        "cookiebar": {
            "term": "We use cookies to adapt our website to your personal preferences and to improve our site. What to know more? Read our {0}",
            "privacy_policy": "privacy policy",
            "privacy_policy_link": "/en/privacy",
            "change_preferences": "Adjust",
            "saving": "Saving…",
            "accept": "Accept",
            "decline": "No thank you"
        }
    }
}
</i18n>

<style lang="less" src="./CookieBar.less" />
