<template>
    <figure
        class="base-image"
        :data-loaded="loaded ? 'true' : 'false'"
    >
        <div
            :style="{
                paddingBottom: `${ height/width*100 }%`,
            }"
            class="base-image__wrapper"
        >
            <picture>
                <source
                    v-if="webpSrcset !== undefined"
                    type="image/webp"
                    :srcset="webpSrcset"
                    :sizes="sizes"
                >

                <source
                    :srcset="srcset"
                    :sizes="sizes"
                >

                <img
                    ref="img"
                    class="base-image__highres"
                    :src="src"
                    :alt="alt"
                    :title="title"
                    :width="width"
                    :height="height"
                    :style="imgStyleAttrs"
                    loading="lazy"
                    @load="loadComplete"
                >
            </picture>
            <div
                v-if="srcTiny"
                :style="{
                    backgroundImage: `url('${ srcTiny }')${ bgStyleAttrs ? '; ' + bgStyleAttrs : '' }`
                }"
                class="base-image__lowres"
            />
        </div>

        <figcaption
            v-if="caption || credits"
            class="base-image__caption"
        >
            <span v-if="caption" v-html="caption" /> <!-- eslint-disable-line -->
            <span v-if="credits && caption"> - </span>
            <span v-if="credits" v-html="credits" /> <!-- eslint-disable-line -->
        </figcaption>
    </figure>
</template>

<script setup>
const props = defineProps({
    src: {
        type: String,
        default: '',
        required: true
    },

    srcset: {
        type: String,
        default: undefined,
        required: false
    },

    webpSrcset: {
        type: String,
        default: undefined,
        required: false
    },

    sizes: {
        type: String,
        default: undefined,
        required: false
    },

    srcTiny: {
        type: String,
        default: undefined
    },

    title: {
        type: String,
        default: ''
    },

    alt: {
        type: String,
        default: ''
    },

    caption: {
        type: String,
        default: ''
    },

    width: {
        type: [String, Number],
        required: true
    },

    height: {
        type: [String, Number],
        required: true
    },

    credits: {
        type: String,
        default: null
    },

    hasFocalPoint: {
        type: Boolean,
        default: false
    },

    focalPoint: {
        type: Array,
        default: () => {}
    }
});

const img = ref(null);

const loaded = ref(false);

const loadComplete = () => {
    loaded.value = true;
};

onMounted(() => {
    // if image was loaded before the component was started, mark as loaded directly.
    loaded.value = img?.value?.complete ?? false;
});

const focalPointAttr = computed(() => {
    if (props.hasFocalPoint && props.focalPoint) {
        return `${props.focalPoint[0] * 100}% ${props.focalPoint[1] * 100}%`;
    }
    return '';
});

const imgStyleAttrs = computed(() => {
    if (props.hasFocalPoint && props.focalPoint) {
        return `object-position: ${focalPointAttr.value}`;
    }
    return '';
});

const bgStyleAttrs = computed(() => {
    if (props.hasFocalPoint && props.focalPoint) {
        return `background-position: ${focalPointAttr.value}`;
    }
    return '';
});
</script>

<style lang="less" src="./BaseImage.less" />
