import AnnouncementGlobalSetQuery from '~/graphql/queries/announcement/globalSet.graphql';
import { useQuery } from '~/composables/villus';

export async function useAnnouncementQuery() {
    const { data, error, isFetching, isDone, execute } = await useQuery(
        AnnouncementGlobalSetQuery,
    );

    return {
        data, error, isFetching, isDone, execute
    };
}
