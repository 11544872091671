<template>
    <slot name="FormieInput">
        <DnInput
            v-bind="$attrs"
            :clearable="false"
        />
    </slot>
</template>

<script>
import { DnInput } from '@digitalnatives/form-input';

export default {
    components: {
        DnInput
    }
};
</script>
