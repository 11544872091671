import { defineStore } from 'pinia';

export const usePageStore = defineStore('pages-store', {
    state: () => {
        return {
            _currentPageTitle: null
        };
    },

    getters: {
        currentPageTitle() {
            return this._currentPageTitle;
        }
    },

    actions: {
        setCurrentPageTitle(payload) {
            this._currentPageTitle = payload.title;
        }
    }
});
