import { H3Error } from 'h3';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.errorHandler = (error: unknown | H3Error) => {
        if (
            error instanceof H3Error &&
            error.statusCode !== 404
        ) {
            useBugsnag().notify(JSON.stringify(error));
        }
    };
});
