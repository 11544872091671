import { kebabCase } from 'lodash-es';

// Ported and adapted from DN toolkit:
// https://gitlab.digitalnatives.nl/toolkit/toolkit/-/blob/vue3/toolkit/packages/icon/icon.vue

const getFileNameFromPath = (path) => {
    return path
        .replace(/^(?:.*)(?:\/assets\/img\/icons\/)(.+)\.svg$/i, '$1')
        .replace('/', '-');
};

const useImportIcons = () => {
    // Redefine bash path, can only use literals
    const iconImports = import.meta.glob(
        '@/assets/img/icons/**/*.svg',
        {
            query: 'component=true&lang.svg',
            import: 'default',
        }
    );

    const imported = [];
    Object.keys(iconImports).forEach((path) => {
        const iconName = kebabCase(getFileNameFromPath(path));
        imported.push(iconName);
    });

    return imported;
};

export default useImportIcons;
