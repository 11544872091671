import { default as _91_46_46_46slug_935yENQ1RwOzMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/[...slug].vue?macro=true";
import { default as _91slug_93OP3QPtdWd1Meta } from "/builds/joods-cultureel-kwartier/jck-app/pages/agenda/[slug].vue?macro=true";
import { default as indexPXryP0K7dwMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/agenda/index.vue?macro=true";
import { default as _91slug_93v6nThU3ZRTMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/education/[slug].vue?macro=true";
import { default as article8iBtIEVU3AMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/examples/article.vue?macro=true";
import { default as foundationtZ4RaL4acQMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/examples/foundation.vue?macro=true";
import { default as for_45families_45and_45childrennJResdN5I0Meta } from "/builds/joods-cultureel-kwartier/jck-app/pages/for-families-and-children.vue?macro=true";
import { default as for_45schools5oIIP6fFICMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/for-schools.vue?macro=true";
import { default as indexlU4mc15orQMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/index.vue?macro=true";
import { default as _91slug_93V6W3IIssP5Meta } from "/builds/joods-cultureel-kwartier/jck-app/pages/location/[location]/[slug].vue?macro=true";
import { default as _91slug_93QovGUWAuIpMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/location/[slug].vue?macro=true";
import { default as plan_45your_45visit5IiJ7NMIjbMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/plan-your-visit.vue?macro=true";
import { default as _91slug_93KAd14BUx2kMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/press/[type]/[slug].vue?macro=true";
import { default as imageryFgm8sgS7fjMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/press/imagery.vue?macro=true";
import { default as index1wautn3AkCMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/press/index.vue?macro=true";
import { default as news5Y1sf1km4XMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/press/news.vue?macro=true";
import { default as releasesmZUCSWaK1YMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/press/releases.vue?macro=true";
import { default as _91slug_93F6joyaOYe3Meta } from "/builds/joods-cultureel-kwartier/jck-app/pages/routes/[slug].vue?macro=true";
import { default as index98BS8FCElMMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/routes/index.vue?macro=true";
import { default as searchEcf2owuHk3Meta } from "/builds/joods-cultureel-kwartier/jck-app/pages/search.vue?macro=true";
import { default as _91slug_93JDhVN1z6sYMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/space/[slug].vue?macro=true";
import { default as _91slug_93UlnpKjrzRgMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/stories-and-analysis/[slug].vue?macro=true";
import { default as index8j3D0F3JsTMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/stories-and-analysis/index.vue?macro=true";
import { default as _91slug_93CM4m8oWVVUMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/theme/[slug].vue?macro=true";
import { default as _91slug_93OEbd8ea8sWMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/tickets/[slug].vue?macro=true";
import { default as index3wP8LftdBfMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/tickets/index.vue?macro=true";
import { default as travel_45tradeeeNKen701fMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/travel-trade.vue?macro=true";
import { default as _91slug_93umRn3FaBHMMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/vacancy/[slug].vue?macro=true";
import { default as whats_45onuWKMh9RBmDMeta } from "/builds/joods-cultureel-kwartier/jck-app/pages/whats-on.vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "agenda-slug___en",
    path: "/en/agenda/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/agenda/[slug].vue").then(m => m.default || m)
  },
  {
    name: "agenda-slug___nl",
    path: "/agenda/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/agenda/[slug].vue").then(m => m.default || m)
  },
  {
    name: "agenda___en",
    path: "/en/agenda",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/agenda/index.vue").then(m => m.default || m)
  },
  {
    name: "agenda___nl",
    path: "/agenda",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/agenda/index.vue").then(m => m.default || m)
  },
  {
    name: "education-slug___en",
    path: "/en/education/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/education/[slug].vue").then(m => m.default || m)
  },
  {
    name: "education-slug___nl",
    path: "/educatie/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/education/[slug].vue").then(m => m.default || m)
  },
  {
    name: "examples-article___en",
    path: "/en/examples/article",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/examples/article.vue").then(m => m.default || m)
  },
  {
    name: "examples-article___nl",
    path: "/examples/article",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/examples/article.vue").then(m => m.default || m)
  },
  {
    name: "examples-foundation___en",
    path: "/en/examples/foundation",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/examples/foundation.vue").then(m => m.default || m)
  },
  {
    name: "examples-foundation___nl",
    path: "/examples/foundation",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/examples/foundation.vue").then(m => m.default || m)
  },
  {
    name: "for-families-and-children___en",
    path: "/en/for-families-and-children",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/for-families-and-children.vue").then(m => m.default || m)
  },
  {
    name: "for-families-and-children___nl",
    path: "/voor-families-en-kinderen",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/for-families-and-children.vue").then(m => m.default || m)
  },
  {
    name: "for-schools___en",
    path: "/en/for-schools",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/for-schools.vue").then(m => m.default || m)
  },
  {
    name: "for-schools___nl",
    path: "/voor-scholen",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/for-schools.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "location-location-slug___en",
    path: "/en/location/:location()/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/location/[location]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "location-location-slug___nl",
    path: "/locatie/:location()/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/location/[location]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "location-slug___en",
    path: "/en/location/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/location/[slug].vue").then(m => m.default || m)
  },
  {
    name: "location-slug___nl",
    path: "/locatie/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/location/[slug].vue").then(m => m.default || m)
  },
  {
    name: "plan-your-visit___en",
    path: "/en/plan-your-visit",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/plan-your-visit.vue").then(m => m.default || m)
  },
  {
    name: "plan-your-visit___nl",
    path: "/plan-je-bezoek",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/plan-your-visit.vue").then(m => m.default || m)
  },
  {
    name: "press-type-slug___en",
    path: "/en/press/:type()/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/press/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "press-type-slug___nl",
    path: "/pers/:type()/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/press/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "press-imagery___en",
    path: "/en/press/imagery",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/press/imagery.vue").then(m => m.default || m)
  },
  {
    name: "press-imagery___nl",
    path: "/pers/beeldmateriaal",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/press/imagery.vue").then(m => m.default || m)
  },
  {
    name: "press___en",
    path: "/en/press",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press___nl",
    path: "/pers",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press-news___en",
    path: "/en/press/news",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/press/news.vue").then(m => m.default || m)
  },
  {
    name: "press-news___nl",
    path: "/pers/nieuws",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/press/news.vue").then(m => m.default || m)
  },
  {
    name: "press-releases___en",
    path: "/en/press/press-releases",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/press/releases.vue").then(m => m.default || m)
  },
  {
    name: "press-releases___nl",
    path: "/pers/persberichten",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/press/releases.vue").then(m => m.default || m)
  },
  {
    name: "routes-slug___en",
    path: "/en/route/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/routes/[slug].vue").then(m => m.default || m)
  },
  {
    name: "routes-slug___nl",
    path: "/route/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/routes/[slug].vue").then(m => m.default || m)
  },
  {
    name: "routes___en",
    path: "/en/routes",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/routes/index.vue").then(m => m.default || m)
  },
  {
    name: "routes___nl",
    path: "/routes",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/routes/index.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___nl",
    path: "/zoeken",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "space-slug___en",
    path: "/en/space/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/space/[slug].vue").then(m => m.default || m)
  },
  {
    name: "space-slug___nl",
    path: "/ruimte/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/space/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stories-and-analysis-slug___en",
    path: "/en/stories-and-analysis/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/stories-and-analysis/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stories-and-analysis-slug___nl",
    path: "/verhalen-en-verdieping/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/stories-and-analysis/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stories-and-analysis___en",
    path: "/en/stories-and-analysis",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/stories-and-analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "stories-and-analysis___nl",
    path: "/verhalen-en-verdieping",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/stories-and-analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "theme-slug___en",
    path: "/en/theme/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/theme/[slug].vue").then(m => m.default || m)
  },
  {
    name: "theme-slug___nl",
    path: "/thema/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/theme/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tickets-slug___en",
    path: "/en/tickets/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tickets-slug___nl",
    path: "/tickets/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tickets___en",
    path: "/en/tickets",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "tickets___nl",
    path: "/tickets",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "travel-trade___en",
    path: "/en/travel-trade",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/travel-trade.vue").then(m => m.default || m)
  },
  {
    name: "travel-trade___nl",
    path: "/traveltrade",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/travel-trade.vue").then(m => m.default || m)
  },
  {
    name: "vacancy-slug___en",
    path: "/en/vacancy/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/vacancy/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacancy-slug___nl",
    path: "/vacature/:slug()",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/vacancy/[slug].vue").then(m => m.default || m)
  },
  {
    name: "whats-on___en",
    path: "/en/whats-on",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/whats-on.vue").then(m => m.default || m)
  },
  {
    name: "whats-on___nl",
    path: "/nu-te-zien-en-doen",
    component: () => import("/builds/joods-cultureel-kwartier/jck-app/pages/whats-on.vue").then(m => m.default || m)
  }
]