<template>
    <div class="menu-overlay__actions">
        <CraftLink
            v-if="callToAction && isLargeScreen"
            :link="callToAction"
            class="button dn-button base-button menu-overlay__cta"
            @click="navigationStore.closeMainMenu()"
        >
            {{ callToAction?.text }}
            <BaseIcon icon="arrow-right" />
        </CraftLink>

        <NuxtLink
            :to="localePath('search')"
            @click="navigationStore.closeMainMenu()"
        >
            <BaseIcon class="menu-overlay__search" icon="search" />
        </NuxtLink>

        <BaseIcon class="menu-overlay__close" icon="cross" @click="navigationStore.closeMainMenu()" />
    </div>
</template>

<script setup>
import { useNavigationStore } from '~/store/navigation';

const localePath = useLocalePath();
const navigationStore = useNavigationStore();

defineProps({
    callToAction: {
        type: Object,
        required: true
    },
    isLargeScreen: {
        type: Boolean,
        default: false
    }
});
</script>
