export const useHeroData = (entry) => {
    return {
        title: entry?.heroTitle || entry?.title,
        description: entry?.heroDescription,
        image: entry?.heroImages ? entry?.heroImages[0] : null,
        infoList: entry?.heroInfoList?.length > 0 ? entry?.heroInfoList : null,
        type: entry?.typeHandle,
        section: entry?.sectionHandle,
        educationContentType: entry?.educationContentType && entry?.educationContentType?.length ? entry.educationContentType[0].title : null,
        eventType: entry?.eventType && entry?.eventType?.length ? entry.eventType[0].title : null,
        pressType: entry?.pressType && entry?.pressType?.length ? entry.pressType[0].title : null,
        eventTypeFilters: entry?.eventTypeFilters || null,
        eventTagFilters: entry?.eventTagFilters || null,
        exhibitionFilters: entry?.exhibitionFilters || false,
        date: entry?.date,
        dateRange: entry?.dateRange,
        collectionType: entry?.collectionType && entry.collectionType[0] ? entry.collectionType[0].title : null
    };
};
