<template>
    <DnButton
        class="base-button"
        v-bind="$attrs"
    >
        <slot />
    </DnButton>
</template>

<script>
import DnButton from '@digitalnatives/button';

export default {
    components: {
        DnButton
    }
};
</script>

<style src="./BaseButton.less" lang="less"></style>
