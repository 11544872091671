<template>
    <nav class="navigation-bar" :has-scrolled="hasScrolled">
        <div class="navigation-bar__inner">
            <NuxtLink :to="localePath('/')">
                <TheLogo
                    :variant="logo"
                    class="navigation-bar__logo"
                />
            </NuxtLink>

            <ClientOnly>
                <div v-show="!!hasScrolled" class="navigation-bar__title">
                    {{ pagesStore?.currentPageTitle }}
                </div>
            </ClientOnly>

            <div class="navigation-bar__actions">
                <CraftLink
                    v-if="callToAction"
                    :style="hasScrolled ? 'display: none' : 'display: block'"
                    class="button dn-button base-button navigation-bar__cta"
                    :link="callToAction"
                    @click="$gtmPush({ event :'cta_click', subject: callToAction?.text })"
                >
                    {{ callToAction?.text }}
                </CraftLink>

                <BaseIcon
                    icon="menu"
                    class="navigation-bar__menu"
                    @click="navigationStore.openMainMenu()"
                />
            </div>
        </div>
    </nav>
</template>

<script setup>
import { usePageStore } from '~/store/pages';
import { useNavigationStore } from '~/store/navigation';

const pagesStore = usePageStore();
const navigationStore = useNavigationStore();
const localePath = useLocalePath();
const hasScrolled = useScrollThreshold();
const { locale } = useI18n();

const props = defineProps({
    callToAction: {
        type: Object,
        required: true,
        default: () => {}
    }
});

const logo = computed(() => {
    if (hasScrolled.value) {
        return 'small';
    } else if (locale.value === 'nl') {
        return 'nl';
    } else if (locale.value === 'en') {
        return 'en';
    }
});
</script>

<style lang="less" src="./TheNavBar.less"></style>
