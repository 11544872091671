<template>
    <LayoutDefault v-if="error.statusCode === 404">
        <ErrorPage v-if="entry" :entry="entry" />
    </LayoutDefault>

    <pre v-else>{{ error }}</pre>
</template>

<script setup>
import LayoutDefault from '~/layouts/default.vue';
import { useErrorQuery } from '~/composables/villus/error/query.ts';
import useColorTheme from '~/composables/useColorTheme';

const props = defineProps({
    error: {
        type: Object,
        default: null
    }
});

const data = ref(null);

data.value = await useErrorQuery();

const entry = data.value?.data?.data?.entry;

useHead({
    title: entry.title
});

useColorTheme({
    inverse: true
});
</script>
