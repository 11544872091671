import { unref, computed } from 'vue';
import { murmurHash } from 'ohash';
import { flatMapDeep } from 'lodash-es';
import { QueryCompositeOptions, QueryVariables } from 'villus';
import { DocumentNode } from 'graphql';

function asyncHandler<TData = any, TVars = QueryVariables>(
    opts: Readonly<QueryCompositeOptions<TData, TVars>>,
) {
    const { $vClient } = useNuxtApp();

    return async() => {
        const response = await $vClient.executeQuery(opts);

        if (response.error) {
            console.error(response.error);

            if (useRuntimeConfig().APP_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.error(response.error);
            }

            throw new Error(response.error);
        }

        return unref(response);
    };
}

/**
 * Hash nested and MaybeRef'd objects in a fast and consistent way.
 * @param thing
 */
const _hash = (thing: Readonly<object | any[]>) => {
    return murmurHash(
        JSON.stringify(
            flatMapDeep(
                thing,
                (value: any, index: number) => [index, unref(value)]
            )
        )
    );
};

export function useQuery(query: DocumentNode, variables = {}) {
    const { $i18n } = useNuxtApp();
    const locale = unref($i18n.locale);

    const opts = {
        query,
        variables: {
            site: locale,
            ...variables
        }
    };

    const key = computed(() => _hash(opts).toString(36));

    return useAsyncData(key.value, asyncHandler(opts));
}

export function useLazyQuery(query: DocumentNode, variables = {}) {
    const { $i18n } = useNuxtApp();
    const locale = unref($i18n.locale);

    const opts = {
        query,
        variables: {
            site: locale,
            ...variables
        }
    };

    const key = computed(() => _hash(opts).toString(36));

    return useLazyAsyncData(key.value, asyncHandler(opts));
}
