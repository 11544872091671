<template>
    <nuxt-link
        v-if="type === NUXT_LINK && url"
        :to="linkUrl(url)"
        @click="$emit('click')"
    >
        <slot />
    </nuxt-link>

    <a
        v-else-if="type === LINK"
        :href="url"
        rel="noopener noreferrer"
        :target="blank ? '_blank' : null"
        @click="$emit('click')"
    >
        <slot />
    </a>
</template>

<script setup>
import { LINK, NUXT_LINK } from '~/constants/links';
import { HOME_URI } from '~/constants/page-uri';

defineEmits(['click']);

const props = defineProps({
    type: {
        type: String,
        validator: value => [LINK, NUXT_LINK].includes(value),
        default: NUXT_LINK
    },
    url: {
        type: [String, Object],
        required: true
    },
    blank: {
        type: Boolean,
        default: false
    }
});

const linkUrl = (uri) => {
    if (uri === HOME_URI) {
        return '/';
    }

    return uri;
};
</script>
