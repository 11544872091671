<template>
    <nav v-show="items.length > 0" class="language-switcher">
        <template
            v-for="item in items"
            :key="item.language.code"
        >
            <a
                class="button dn-button base-button"
                :href="item.uri"
            >
                {{ $t('language-switcher.switch-to', {
                    language: item.language,
                    languageCode: $t(`language-switcher.languageCode.${item.language.code}`)
                }) }}
            </a>
        </template>
    </nav>
</template>

<script setup>
import { ref, useLocalePath, useI18n, useRoute, useSwitchLocalePath, watch } from '#imports';
import { useQuery } from '~/composables/villus';
import { HOME_URI } from '~/constants/page-uri';

const props = defineProps(
    {
        hideUnavailable: {
            type: Boolean,
            default: false
        }
    }
);

const query = `query localizedUri($uri: [String!], $site: [String!]!) {
    entry(uri: $uri, site: $site) {
        uri
        language
        localized {
            uri
            language
        }
    }
}`;

const items = ref([]);
const data = ref(null);
const route = useRoute();
const localePath = useLocalePath();
const switchLocalePath = useSwitchLocalePath();
const { locale, locales } = useI18n();
const currentLocale = ref(locale);

const fetchOtherPages = async() => {
    let uri = route?.path;

    // Remove any locale prefix from URI.
    if (uri.startsWith(`/${unref(currentLocale)}/`)) {
        uri = uri.replace(`/${unref(currentLocale)}/`, '');
    }

    if (uri.endsWith('/')) {
        uri = uri.slice(1);
    }

    if (uri.startsWith('/')) {
        uri = uri.substring(1);
    }

    const variables = ref({
        site: unref(currentLocale),
        uri: uri || HOME_URI
    });

    data.value = null;

    const response = await useQuery(
        query,
        unref(variables)
    );

    return response?.data?.value?.data;
};

data.value = await fetchOtherPages();

const getOtherLocales = (allLocales) => {
    return allLocales.value.filter(i => i.code !== currentLocale.value);
};

const otherLangs = ref(getOtherLocales(locales, locale));

const updateItems = (allLocales) => {
    items.value = [];
    allLocales.value.forEach((language) => {
        // No data means this is likely not a craft entry, so return the locally stored URI
        if (data.value && !data.value.entry) {
            items.value.push({
                language,
                isFallback: false,
                uri: switchLocalePath(language.code)
            });
        } else if (data.value.entry.localized.length) {
            // Attempt to map language codes between nuxt and craft
            const craftCode = language.craftSiteHandle || language.code || language.iso;
            const localizedEntry = data.value.entry.localized.filter(l => l.language === craftCode)[0];

            if (localizedEntry) {
                if (localizedEntry.uri === HOME_URI) {
                    localizedEntry.uri = '/';
                }
                // Entry is available in this language
                items.value.push({
                    language,
                    isFallback: false,
                    uri: localePath('/' + localizedEntry.uri, language.code)
                });
            } else if (!props.hideUnavailable) {
                // If entry is not available in this language, point to the home page
                items.value.push({
                    language,
                    isFallback: true,
                    uri: localePath('/', language.code)
                });
            }
        }
    });
};

updateItems(otherLangs);

watch(otherLangs, () => {
    updateItems(otherLangs);
});

watch(() => route.path, async(newRoute) => {
    data.value = await fetchOtherPages();

    otherLangs.value = getOtherLocales(locales, locale);
}, { deep: true });
</script>
