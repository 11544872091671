<template>
    <header class="hero">
        <div v-if="!!$slots.title" class="hero__title">
            <h1>
                <slot name="title" />
            </h1>
        </div>

        <div v-if="!!$slots.default" class="hero__details">
            <slot />
        </div>

        <div v-if="!!$slots.meta" class="hero__meta">
            <slot name="meta" />
        </div>

        <!-- eslint-disable vue/no-v-html -->
        <div
            v-if="!!props.description"
            class="hero__description"
            v-html="props.description"
        />
        <!-- eslint-enable vue/no-v-html -->
        <div v-else-if="!!$slots.description" class="hero__description">
            <slot name="description" />
        </div>

        <div
            v-if="!!props.infoList"
            class="hero__info-list"
        >
            <InfoList :list-items="props.infoList" />
        </div>

        <div
            v-if="!!props.image || !!$slots.image"
            class="hero__image"
        >
            <template v-if="!$slots.image">
                <BaseImage
                    :src="props.image?.w320"
                    :src-tiny="image.w32"
                    :srcset="`
                        ${props.image?.w320} 320w,
                        ${props.image?.w768} 768w,
                        ${props.image?.w980} 980w,
                        ${props.image?.w1260} 1260w,
                        ${props.image?.w1920} 1920w
                    `"
                    sizes="
                        (max-width: 380px) 320px,
                        (max-width: 768px) 768px,
                        (max-width: 1260px) 1260px
                    "
                    :height="props.image.height"
                    :width="props.image.width"
                    :alt="props.image.alt"
                    :has-focal-point="props.image.hasFocalPoint"
                    :focal-point="props.image.focalPoint"
                />
            </template>
            <template v-else-if="!!$slots.image">
                <slot name="image" />
            </template>
        </div>

        <div
            v-if="!!$slots.filters"
            class="hero__filters"
        >
            <slot name="filters" />
        </div>

        <div
            v-if="!!$slots.callToAction"
            class="hero__call-to-action"
        >
            <slot name="callToAction" />
        </div>
    </header>
</template>

<script setup>
const props = defineProps({
    infoList: {
        type: Array,
        default: null
    },
    image: {
        type: Object,
        default: null
    },
    description: {
        type: String,
        default: null
    }
});
</script>

<style lang="less" src="./BaseHero.less" />
