export default defineNuxtPlugin(() => {
    return {
        provide: {
            gtmPush(opts = {}) {
                if (!window.dataLayer) {
                    // eslint-disable-next-line no-console
                    console.error('GTM not installed');
                }

                try {
                    window.dataLayer.push(opts);
                } catch (e) {
                    useBugsnag().notify(JSON.stringify(e));
                }
            }
        }
    };
});
