import { computed } from 'vue';

const DEFAULT_THEME = 'default';
const INVERSED_THEME = 'inverse';

// Keep a 'global' ref so we can return it from `getCurrentTheme`
const _colorTheme = ref();

const useColorTheme = (options) => {
    const hasScrolled = useScrollThreshold(300);

    const inversed = !!options?.inverse;

    const colorTheme = computed(() => {
        const regularTheme = inversed ? INVERSED_THEME : DEFAULT_THEME;
        const themeOnScroll = inversed ? DEFAULT_THEME : INVERSED_THEME;

        if (options?.switchOnScroll && hasScrolled.value) {
            _colorTheme.value = themeOnScroll;
            return themeOnScroll;
        }

        _colorTheme.value = regularTheme;
        return regularTheme;
    });

    useHead({
        bodyAttrs: {
            'color-theme': colorTheme
        }
    });
};

export default useColorTheme;

export const getCurrentTheme = computed(() => _colorTheme);
