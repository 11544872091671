<template>
    <transition name="menu-overlay">
        <div
            v-show="navigationStore.isMainMenuOpen"
            class="menu-overlay"
            role="dialog"
            :has-scrolled="hasScrolled"
        >
            <div ref="scrollArea" class="menu-overlay__scroll">
                <div class="menu-overlay__body">
                    <div class="menu-overlay__main">
                        <div class="menu-overlay__head">
                            <div class="menu-overlay__logo">
                                <div class="menu-overlay__logo-wrapper">
                                    <NuxtLink
                                        :to="localePath('/')"
                                        @click="navigationStore.closeMainMenu()"
                                    >
                                        <TheLogo :variant="logo" />
                                    </NuxtLink>

                                    <TheLanguageSwitcher />
                                </div>
                            </div>

                            <ClientOnly>
                                <TheMenuOverlayActions
                                    v-if="!isLargeScreen"
                                    :call-to-action="menu.secondaryCallToAction"
                                />
                            </ClientOnly>
                        </div>

                        <ClientOnly>
                            <div class="menu-overlay__main-menu">
                                <BaseDnAccordion
                                    :items="menuItems"
                                    :scroll-into-view="false"
                                >
                                    <template #title="{ item }">
                                        <div @click="switchTab(item.slug)">
                                            {{ item.title }}
                                        </div>
                                    </template>

                                    <template #default="{ item }">
                                        <template v-if="item.links && item.links.length">
                                            <CraftLink
                                                v-for="(link, index) in filteredLinks(item.links)"
                                                :key="`main-menu-${item.slug}-link-${index}`"
                                                :link="link.entry"
                                                @click="navigationStore.closeMainMenu()"
                                            >
                                                {{ link.entry?.text }}
                                            </CraftLink>

                                            <aside
                                                v-if="item.slug === 'title1' && !isLargeScreen"
                                                class="menu-overlay__locations"
                                            >
                                                <h2>{{ t('main-menu.locations') }}</h2>
                                                <TheMenuOverlaySlider slider-type="locations" :location-entries="menu.locations" />
                                            </aside>

                                            <aside
                                                v-else-if="item.slug === 'title2' && !isLargeScreen"
                                                class="menu-overlay__see-and-do"
                                            >
                                                <h2>{{ t('main-menu.see-and-do') }}</h2>
                                                <TheMenuOverlaySlider slider-type="cards" :featured-entries="menu.seeAndDo" />
                                            </aside>
                                        </template>
                                    </template>

                                    <template #icon="{ item }">
                                        <BaseIcon :icon="menuIcon(item)" @click="switchTab(item.slug)" />
                                    </template>
                                </BaseDnAccordion>

                                <CraftLink
                                    v-if="menu.secondaryCallToAction && !isLargeScreen"
                                    class="button dn-button base-button menu-overlay__cta"
                                    :link="menu.secondaryCallToAction"
                                    @click="
                                        navigationStore.closeMainMenu();
                                        $gtmPush({ event: 'cta_click', subject: menu.secondaryCallToAction.text });
                                    "
                                >
                                    {{ menu.secondaryCallToAction?.text }}
                                    <BaseIcon icon="arrow-right" />
                                </CraftLink>
                            </div>
                        </ClientOnly>
                    </div>

                    <ClientOnly>
                        <TheMenuOverlayActions
                            v-if="isLargeScreen"
                            :call-to-action="menu.secondaryCallToAction"
                            :is-large-screen="isLargeScreen"
                        />

                        <aside
                            v-if="activeTab === 'title1' && isLargeScreen"
                            class="menu-overlay__locations"
                        >
                            <MetaLine direction="vertical">
                                {{ t('main-menu.locations') }}
                            </MetaLine>
                            <TheMenuOverlaySlider
                                slider-type="locations"
                                :location-entries="menu.locations"
                                @click="navigationStore.closeMainMenu()"
                            />
                        </aside>

                        <aside
                            v-else-if="activeTab === 'title2' && isLargeScreen"
                            class="menu-overlay__see-and-do"
                        >
                            <MetaLine direction="vertical">
                                {{ t('main-menu.see-and-do') }}
                            </MetaLine>
                            <TheMenuOverlaySlider
                                slider-type="cards"
                                :featured-entries="menu.seeAndDo"
                                @click="navigationStore.closeMainMenu()"
                            />
                        </aside>
                    </ClientOnly>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { useMediaQuery, useScroll } from '@vueuse/core';
import { useNavigationStore } from '~/store/navigation';

const props = defineProps({
    menu: {
        type: Object,
        required: true,
        default: () => {}
    }
});

const localePath = useLocalePath();
const navigationStore = useNavigationStore();
const isLargeScreen = useMediaQuery('(min-width: 1025px)');
const { locale, t } = useI18n({ useScope: 'local' });

const activeTab = ref(null);
const scrollArea = ref(null);

const { y } = useScroll(scrollArea);
const hasScrolled = useScrollThreshold(100, y);

const route = useRoute();

// Get only the title keys from the main menu object, then transform it into data
// that is compatible with the accordion component.
const menuItems = computed(() => {
    return Object.keys(props.menu)
        .filter(key => key.startsWith('title'))
        .map((key) => {
            const linksKey = `links${key.substr(-1)}`;
            return {
                title: props.menu[key],
                slug: key,
                links: props.menu[linksKey]
            };
        });
});

// Filter out links that don't have a URL (i.e. links that may not be available in the current locale).
const filteredLinks = links => links.filter(link => link?.entry?.url !== null);

const switchTab = (tab) => {
    // If this is already the active tab, we've closed it and should unset it.
    if (tab === activeTab.value) {
        activeTab.value = null;
    } else {
        activeTab.value = tab;
    }
};

const logo = computed(() => {
    if (hasScrolled.value && isLargeScreen.value) {
        return 'small';
    } else {
        return locale.value;
    }
});

const menuIcon = (item) => {
    if (activeTab.value === item.slug) {
        return 'remove';
    }
    return 'plus';
};

watch(route, () => {
    navigationStore.closeMainMenu();
});

navigationStore.$subscribe(() => {
    if (!navigationStore.isMainMenuOpen) {
        activeTab.value = null;
    }
});
</script>

<i18n>
{
    "nl": {
        "main-menu": {
            "locations": "Onze locaties",
            "see-and-do": "Zien en doen"
        }
    },
    "en": {
        "main-menu": {
            "locations": "Our locations",
            "see-and-do": "See and do"
        }
    }
}
</i18n>

<style lang="less" src="./TheMenuOverlay.less"></style>
