<template>
    <div v-if="mailchimpListUrl" class="newsletter-signup">
        <form :action="mailchimpListUrl" method="get" target="_blank" @submit="$gtmPush({ event: 'newsletter_subscription_submit' })">
            <div class="newsletter-signup__body">
                <BaseFormField>
                    <template v-slot:label>
                        <label for="email">
                            {{ t('label') }}
                        </label>
                    </template>

                    <template v-slot:default>
                        <input type="hidden" name="u" :value="mailchimpSettings.u">
                        <input type="hidden" name="id" :value="mailchimpSettings.id">

                        <BaseInput
                            id="email"
                            v-model="email"
                            type="email"
                            name="MERGE0"
                            :placeholder="t('placeholder')"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField>
                    <BaseButton
                        type="submit"
                        class="button--auto-inverse"
                    >
                        {{ t('subscribe') }}
                    </BaseButton>
                </BaseFormField>
            </div>
        </form>
    </div>
</template>

<script setup>
const { t } = useI18n({ useScope: 'local' });
const email = ref();

const props = defineProps({
    mailchimpListUrl: {
        type: String,
        required: true,
        default: null
    }
});

const urlObj = new URL(props.mailchimpListUrl);
const mailchimpSettings = {
    u: urlObj.searchParams.get('u'),
    id: urlObj.searchParams.get('id')
};
</script>

<i18n>
{
    "en": {
        "subscribe": "Subscribe",
        "label": "Email",
        "placeholder": "Enter your email adress to receive our newsletter"
    },
    "nl": {
        "subscribe": "Inschrijven",
        "label": "E-mail",
        "placeholder": "Vul je e-mailadres in om onze nieuwsbrief te ontvangen"
    }
}
</i18n>

<style lang="less" src="./NewsletterSignup.less"></style>
