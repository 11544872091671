import { createClient, cache, dedup, definePlugin } from 'villus';
import { batch } from '@villus/batch';

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();

    const authPlugin = definePlugin(({ opContext }) => {
        opContext.headers.Authorization = `Bearer ${config.public.apiToken}`;

        const route = useRoute();
        const query = unref(route?.query);

        // Add craft preview token
        if (query?.token) {
            opContext.headers['X-Craft-Token'] = query?.token.toString();
        }
    });

    const client = createClient({
        url: config.public.apiBase,
        use: [authPlugin, batch(), cache(), dedup()]
    });

    return {
        provide: {
            vClient: client
        }
    };
});
