<!-- Currently not in use -->
<template>
    <slot name="FormieRadio">
        <DnRadio v-bind="$attrs">
            <slot />
        </DnRadio>
    </slot>
</template>

<script>
import DnRadio from '@digitalnatives/radio';

export default {
    components: {
        DnRadio,
    },
};
</script>
