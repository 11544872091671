<template>
    <slot name="FormieFile">
        <input v-bind="$attrs" type="file">
    </slot>
</template>

<script>
export default {
    name: 'FormieFile',
};
</script>
