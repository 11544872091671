import MainNavigationQuery from '~/graphql/queries/MainNavigation.graphql';
import { useQuery } from '~/composables/villus';

export async function useMainNavigationQuery() {
    const response = await useQuery(
        MainNavigationQuery
    );

    return unref(response.data);
}
