<template>
    <div
        v-if="announcementData.isActive"
        class="announcement"
    >
        <div class="announcement__container">
            <!-- eslint-disable -->
            <div
                class="announcement__text"
                v-html="announcementData.text"
            />
            <!-- eslint-enable -->
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useAnnouncementQuery } from '~/composables/villus/announcement/query.ts';

const { data } = await useAnnouncementQuery();

const announcementData = data.value.data.globalSet;

</script>

<style lang="less" src="./TheAnnouncement.less"></style>
