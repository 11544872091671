<template>
    <slot name="FormieTextarea">
        <DnTextarea
            v-bind="$attrs"
            :clearable="false"
            :auto-resize="false"
        />
    </slot>
</template>

<script>
import { DnTextarea } from '@digitalnatives/form-input';

export default {
    components: {
        DnTextarea,
    },
};
</script>
