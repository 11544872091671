<template>
    <div class="footer">
        <div class="footer__container">
            <div class="footer__main">
                <div class="footer__tagline">
                    <h2>{{ footerData.footerTitle }}</h2>
                </div>

                <div class="footer__links">
                    <HighlightLinks
                        v-if="highlightLinks"
                        :links="highlightLinks"
                    />
                </div>
            </div>

            <div
                v-if="footerData.mailchimpList"
                class="footer__newsletter-signup"
            >
                <h2>{{ t('newsletter-signup.title') }}</h2>
                <NewsletterSignup
                    :mailchimp-list-url="footerData.mailchimpList"
                />
            </div>

            <div class="footer__partners">
                <h3>{{ $t('partners.title') }}</h3>
                <PartnerLogos :active-index="currentPartnerIndex" :is-inverse-color="true" />
            </div>

            <div
                v-if="footerData.socialLinks && footerData.socialLinks.length"
                class="footer__secondary-links"
            >
                <CraftLink v-for="link in footerData.socialLinks" :key="link.id" :link="link.entry">
                    {{ link.entry.customText }}
                </CraftLink>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useFooterQuery } from '~/composables/villus/footer/query.ts';

const { t } = useI18n({ useScope: 'local' });
const { data } = await useFooterQuery();

const footerData = data.value.data.globalSet;

const highlightLinks = computed(() => {
    if (!footerData || !footerData.links || !footerData.links.length) {
        return false;
    }

    return footerData.links.map(link => link.entry);
});

const { image: currentPartnerIndex, start: startFlipper, stop: stopFlipper } = useFlipper([0, 1, 2, 3], { interval: 2000 });

onMounted(() => {
    startFlipper();
});

onUnmounted(() => {
    stopFlipper();
});
</script>

<style lang="less" src="./TheFooter.less"></style>

<i18n>
{
    nl: {
        'newsletter-signup.title': 'Blijf op de hoogte',
    },
    en: {
        'newsletter-signup.title': 'Stay up-to-date',
    }
}
</i18n>
