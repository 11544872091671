<template>
    <component
        :is="componentType"
        class="menu-overlay-slider"
        v-bind="$attrs"
        :controls-enabled="true"
        track-intersection
        :intersection-threshold="1"
        width="page"
    >
        <template #controlLeft>
            <div class="menu-overlay-slider__left" :is-visible="!firstItemVisible" />
        </template>
        <template #controlRight>
            <div class="menu-overlay-slider__right" :is-visible="!lastItemVisible" />
        </template>
    </component>
</template>

<script setup>
import { useAttrs } from 'vue';

// Madness, these attrs are reactive, but obtaining them isn't easy.
const attrs = useAttrs();
const items = Object.keys(attrs).includes('location-entries') ? attrs['location-entries'] : attrs['featured-entries'];

const firstItemVisible = computed(() => {
    return items[0].isVisible;
});

const lastItemVisible = computed(() => {
    return items[items.length - 1].isVisible;
});

const props = defineProps({
    sliderType: {
        type: String,
        required: true,
        validator: value => ['locations', 'cards'].includes(value)
    }
});

const componentType = computed(() => {
    if (props.sliderType === 'locations') {
        return 'FlexibleSectionLocationSlider';
    } else if (props.sliderType === 'cards') {
        return 'FlexibleSectionCardSlider';
    }
});
</script>

<style lang="less" src="./TheMenuOverlaySlider.less" />
