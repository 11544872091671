import { LINK, NUXT_LINK } from '~/constants/links';
import { HOME_URI } from '~/constants/page-uri';

export const useCraftLinkType = (type) => {
    switch (type) {
    case 'email':
    case 'tel':
    case 'url':
        return LINK;
    case 'entry':
    case 'category':
        return NUXT_LINK;
    }
};

export const useFixCraftLinkHomeUri = (uri) => {
    const localePath = useLocalePath();

    if (uri === HOME_URI) {
        return localePath({
            path: '/'
        });
    }

    return localePath({
        path: `/${uri}`
    });
};

export const useCraftLinkURI = (link) => {
    const type = useCraftLinkType(link.type);

    if (type === NUXT_LINK) {
        return useFixCraftLinkHomeUri(link?.element?.uri);
    }

    return link.url;
};

export const useIsCraftLinkExternal = (link) => {
    return link.target === '_blank';
};
