<template>
    <client-only>
        <BaseIcon :icon="props.icon ? props.icon : randomIcon" v-bind="$attrs" />
    </client-only>
</template>

<script setup>
import useImportIcons from '~/composables/useIcons';

const props = defineProps({
    icon: {
        type: String,
        default: null
    },
    exclude: {
        type: Array,
        default: () => []
    }
});

const icons = useImportIcons().filter(icon => icon.startsWith('jck') && !props.exclude.includes(icon) && !icon.endsWith('-junior'));
const randomIcon = icons[Math.round(Math.random() * (icons.length - 1))];
</script>
