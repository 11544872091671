import { ref } from 'vue';

export const useFlipper = (valueImages, valueOptions) => {
    let timer;
    const image = ref();
    const images = ref([]);
    const imageIndex = ref(0);
    const options = ref({ interval: 2000 });

    function changeImage() {
        image.value = images.value[imageIndex.value];
        imageIndex.value++;
        if (imageIndex.value >= images.value.length) {
            imageIndex.value = 0;
        }
    }

    /**
     * Start function won't work in SSR mode
     */
    function start() {
        stop();
        timer = setInterval(() => {
            changeImage();
        }, options.value.interval);
    }

    function stop() {
        clearInterval(timer);
    }

    images.value = valueImages;
    options.value = valueOptions;
    changeImage();

    return {
        image,
        start,
        stop
    };
};
