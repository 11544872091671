import { useWindowScroll } from '@vueuse/core';

const useScrollThreshold = (scrollThreshold = 50, destination) => {
    const myDestination = ref(destination);
    if (!destination) {
        ({ y: myDestination.value } = useWindowScroll());
    }

    return computed(() => unref(myDestination.value) >= scrollThreshold);
};

export default useScrollThreshold;
