import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFlexibleSectionCardSlider, LazyFlexibleSectionContactPerson, LazyFlexibleSectionAccordion, LazyFlexibleSectionDownload, LazyFlexibleSectionForm, LazyFlexibleSectionImage, LazyFlexibleSectionImages, LazyFlexibleSectionMap, LazyFlexibleSectionNewsletterSignup, LazyFlexibleSectionOpeningHours, LazyFlexibleSectionPartners, LazyFlexibleSectionWordList, LazyFlexibleSectionHighlightLinks, LazyFlexibleSectionInfoList, LazyFlexibleSectionLocationSlider, LazyFlexibleSectionPaymentInfo, LazyFlexibleSectionPodcastEmbed, LazyFlexibleSectionRichText, LazyFlexibleSectionRouteList, LazyFlexibleSectionTeamMembers, LazyFlexibleSectionTextImage, LazyFlexibleSectionTextVideo, LazyFlexibleSectionVideoEmbed } from '#components'
const lazyGlobalComponents = [
  ["FlexibleSectionCardSlider", LazyFlexibleSectionCardSlider],
["FlexibleSectionContactPerson", LazyFlexibleSectionContactPerson],
["FlexibleSectionAccordion", LazyFlexibleSectionAccordion],
["FlexibleSectionDownload", LazyFlexibleSectionDownload],
["FlexibleSectionForm", LazyFlexibleSectionForm],
["FlexibleSectionImage", LazyFlexibleSectionImage],
["FlexibleSectionImages", LazyFlexibleSectionImages],
["FlexibleSectionMap", LazyFlexibleSectionMap],
["FlexibleSectionNewsletterSignup", LazyFlexibleSectionNewsletterSignup],
["FlexibleSectionOpeningHours", LazyFlexibleSectionOpeningHours],
["FlexibleSectionPartners", LazyFlexibleSectionPartners],
["FlexibleSectionWordList", LazyFlexibleSectionWordList],
["FlexibleSectionHighlightLinks", LazyFlexibleSectionHighlightLinks],
["FlexibleSectionInfoList", LazyFlexibleSectionInfoList],
["FlexibleSectionLocationSlider", LazyFlexibleSectionLocationSlider],
["FlexibleSectionPaymentInfo", LazyFlexibleSectionPaymentInfo],
["FlexibleSectionPodcastEmbed", LazyFlexibleSectionPodcastEmbed],
["FlexibleSectionRichText", LazyFlexibleSectionRichText],
["FlexibleSectionRouteList", LazyFlexibleSectionRouteList],
["FlexibleSectionTeamMembers", LazyFlexibleSectionTeamMembers],
["FlexibleSectionTextImage", LazyFlexibleSectionTextImage],
["FlexibleSectionTextVideo", LazyFlexibleSectionTextVideo],
["FlexibleSectionVideoEmbed", LazyFlexibleSectionVideoEmbed],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
