import { defineStore } from 'pinia';

export const useNavigationStore = defineStore('navigation-store', {
    state: () => {
        return {
            mainMenuOpen: false
        };
    },

    getters: {
        isMainMenuOpen() {
            return this.mainMenuOpen;
        }
    },

    actions: {
        openMainMenu() {
            this.mainMenuOpen = true;
        },

        closeMainMenu() {
            this.mainMenuOpen = false;
        }
    }
});
