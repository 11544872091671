<template>
    <GoogleTagManager />

    <div class="layout">
        <SkipLinks />

        <TheNavBar :call-to-action="mainMenu.callToAction" />
        <TheAnnouncement />
        <TheMenuOverlay :menu="mainMenu" />

        <div
            id="main-content-landmark"
            class="layout__main"
            role="main"
        >
            <slot />
        </div>

        <TheFooter id="footer-landmark" />
    </div>

    <Teleport to="body">
        <ClientOnly>
            <CookieBar />
        </ClientOnly>
    </Teleport>
</template>

<script setup>
import { useMainNavigationQuery } from '~/composables/villus/mainNavigation';

useFavicons();
const hasScrolled = useScrollThreshold();
const isMainMenuOpen = useIsMainMenuOpen();

const { locale } = useI18n();

let data = await useMainNavigationQuery();

const mainMenu = ref(data.data?.globalSets[0]);

watch(locale, async() => {
    data = await useMainNavigationQuery();
    mainMenu.value = data?.data?.globalSets[0];
});

useHead({
    bodyAttrs: {
        'has-scrolled': hasScrolled,
        'menu-open': isMainMenuOpen
    },
    htmlAttrs: {
        lang: locale.value
    }
});
</script>

<style lang="less">
//
</style>
