export default () => {
    useHead({
        link: [
            {
                rel: 'shortcut icon',
                href: '/favicons/favicon.ico'
            },
            {
                rel: 'apple-touch-icon',
                type: 'image/png',
                sizes: '180x180',
                href: '/favicons/apple-touch-icon.png'
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '32x32',
                href: '/favicons/favicon-32x32.png'
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '16x16',
                href: '/favicons/favicon-16x16.png'
            },
            {
                rel: 'manifest',
                href: '/favicons/site.webmanifest'
            },
            {
                rel: 'mask-icon',
                href: '/favicons/safari-pinned-tab.svg',
                color: '#000F9F'
            }
        ],
        meta: [
            {
                name: 'msapplication-TileColor',
                content: '#ffffff'
            },
            {
                name: 'theme-color',
                content: '#ffffff'
            }
        ]
    });
};
